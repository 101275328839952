import Header from './components/Header/Header';
import StepperActivation from './components/StepperActivation/StepperActivation';
import Footer from './components/Footer/Footer';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MegatlonThemeJSON from './data/megatlonTheme.json';
import FiterThemeJSON from './data/fiterTheme.json';
import HelveticaNeue from './assets/fonts/HelveticaNeueLTStd-Bd.otf';

const megatlonTheme = createTheme(MegatlonThemeJSON);
const fiterTheme = createTheme({ ...FiterThemeJSON,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'HelveticaNeue';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('HelveticaNeue'), local('HelveticaNeue-Regular'), url(${HelveticaNeue}) format('otf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    }
  }
});

function App() {
  const isFiter = ['true', '1', 't'].includes(process.env.REACT_APP_IS_FITER.toLowerCase());
  return (
    <ThemeProvider theme={isFiter ? fiterTheme : megatlonTheme}>
      <div className="App">
        <Header />
        <StepperActivation />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;