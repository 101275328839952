import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Grid, Step, Stepper, Button, Typography } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import './StepperActivation.css';
import StepBegin from '../StepBegin/StepBegin';
import StepYourData from '../StepYourData/StepYourData';
import StepConfirmation from '../StepConfirmation/StepConfirmation';
import { useTheme } from '@mui/material/styles';
import { activateFreePass, verifyFreePass } from '../../api/api';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import AlertMessage from '../AlertMessage/AlertMessage';
import TextsJSON from '../../data/texts.json';

function StepperActivation() {
  const [activeStep, setActiveStep] = useState(0);
  const [number, setNumber] = useState('');
  const [verifier, setVerifier] = useState('');
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleCloseError = () => { setError(false); };
  const [errorsFirstStep, setErrorsFirstStep] = useState({
    number: '',
    verifier: '',
    terms: '',
    captcha: ''
  });
  const [personalData, setPersonalData] = useState({
    tipoDocumento: '',
    numeroDocumento: '',
    numeroPase: '',
    codigoPase: '',
    apellido: '',
    nombre: '',
    mail: '',
    fechaNacimiento: null,
    sexo: '',
    telefonoCodigoArea: '',
    telefonoNumero: '',
  });
  const [errorsSecondStep, setErrorsSecondStep] = useState({
    tipoDocumento: '',
    numeroDocumento: '',
    numeroPase: '',
    codigoPase: '',
    apellido: '',
    nombre: '',
    mail: '',
    fechaNacimiento: '',
    sexo: '',
    telefonoCodigoArea: '',
    telefonoNumero: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const isFiter = ['true', '1', 't'].includes(process.env.REACT_APP_IS_FITER.toLowerCase());
  const texts = isFiter ? TextsJSON.fiter : TextsJSON.megatlon;
  const steps = texts.steps;
  const buttonLabels = texts.buttonLabels;

  const theme = useTheme();

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
          theme.palette.active.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
          theme.palette.completed.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.defaultNeutral.main,
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    zIndex: 1,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    border: `solid 1px ${theme.palette.text.primary}`,
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: theme.palette.active.main,
      color: theme.palette.active.contrastText,
      border: 'none',
    }),
    ...(ownerState.completed && {
      backgroundColor: theme.palette.completed.main,
      border: 'none',
      color: theme.palette.completed.contrastText,
    }),
  }));

  const ColorlibStepLabel = styled(StepLabel)(() => ({
    [`& .${stepLabelClasses.label}`]: {
      [`&.${stepLabelClasses.active}`]: {
        color: theme.palette.active.main
      },
    }
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        <b>{String(props.icon)}</b>
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  async function validationFirstStep() {
    let validationOk = true;
    let error = {};

    if(!number) {
      error.number = "El número es requerido";
      validationOk = false;
    } else {
      error.number = '';
    }

    if(verifier === '') {
      error.verifier = "Las letras son requeridas";
      validationOk = false;
    } else {
      error.verifier = '';
    }

    if(!terms) {
      error.terms = "Es necesario aceptar los terminos y condiciones para continuar";
      validationOk = false;
    } else {
      error.terms = '';
    }

    if(!window.grecaptcha.getResponse()) {
      error.captcha = "Es necesario que realice el captcha para continuar";
      validationOk = false;
    } else {
      error.captcha = '';
    }

    setErrorsFirstStep(error);
    return validationOk;
  }

  const validationSecondStep = () => {
    let validationOk = true;
    let error = {};
    if(personalData.tipoDocumento === '') {
      error.tipoDocumento = "El tipo de documento es requerido";
      validationOk = false;
    } else {
      error.tipoDocumento = '';
    }

    const regexDoc = /^(?!^0+$)[a-zA-Z0-9]{3,20}$/
    if(!personalData.numeroDocumento) {
      error.numeroDocumento = "El número de documento es requerido";
      validationOk = false;
    } else if(!regexDoc.test(personalData.numeroDocumento)) {
      error.numeroDocumento = "Ingrese un número de documento válido";
      validationOk = false;
    } else {
      error.numeroDocumento = '';
    }

    if(!personalData.nombre) {
      error.nombre = "El nombre es requerido";
      validationOk = false;
    } else {
      error.nombre = '';
    }

    if(!personalData.apellido) {
      error.apellido = "El apellido es requerido";
      validationOk = false;
    } else {
      error.apellido = '';
    }

    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g // eslint-disable-line
    if(!personalData.mail) {
      error.mail = "El email es requerido";
      validationOk = false;
    } else if(!regexEmail.test(personalData.mail)) {
      error.mail = "Ingrese un email válido";
      validationOk = false;
    } else {
      error.mail = '';
    }

    const birthDate = new Date(personalData.fechaNacimiento);
    if(!personalData.fechaNacimiento) {
      error.fechaNacimiento = "La fecha de nacimiento es requerida";
      validationOk = false;
    } else if(!(birthDate instanceof Date && !isNaN(birthDate.valueOf()))) {
      error.fechaNacimiento = "La fecha de nacimiento tiene un formato invalido (debe ser dd/mm/aaaa)";
      validationOk = false;
    } else {
      error.fechaNacimiento = '';
    }

    if(!personalData.sexo) {
      error.sexo = "El sexo es requerido";
      validationOk = false;
    } else {
      error.sexo = '';
    }

    if(personalData.estadoCivil === '') {
      error.estadoCivil = "El estado civil es requerido";
      validationOk = false;
    } else {
      error.estadoCivil = '';
    }

    if(!personalData.telefonoCodigoArea) {
      error.telefonoCodigoArea = "El código de área es requerido";
      validationOk = false;
    } else if(personalData.telefonoCodigoArea.length < 2) {
      error.telefonoCodigoArea = "El código de área debe tener por lo menos 2 dígitos";
      validationOk = false;
    } else {
      error.telefonoCodigoArea = '';
    }

    if(!personalData.telefonoNumero) {
      error.telefonoNumero = "El número de teléfono es requerido";
      validationOk = false;
    } else if(personalData.telefonoNumero.length < 6) {
      error.telefonoFijoNumero = "El número de teléfono fijo debe tener por lo menos 6 dígitos";
      validationOk = false;
    } else {
      error.telefonoFijoNumero = '';
    }
    
    setErrorsSecondStep(error);
    return validationOk;
  }

  async function validation() {
    switch (activeStep) {
      case 0:
        return await validationFirstStep();
      case 1:
        return validationSecondStep();
      default:
        return true;
    }
  }

  async function nextStep() {
    if(await validation()) {
      if(activeStep === 0){
        setLoading(true);
        verifyFreePass(number, verifier, window.grecaptcha.getResponse()).then((response) => {
          if(!response.data) {
            setPersonalData({
              ...personalData,
              numeroPase: number,
              codigoPase: verifier
            });
            setActiveStep(activeStep === steps.length - 1 ? 0 : activeStep + 1);
            setLoading(false);
          } else {
            setError(true);
            setErrorMessage(response.data);
            setLoading(false);
            window.grecaptcha.reset();
          }
        }).catch((error) => {
          setError(true);
          setErrorMessage(error.response?.data ?? "Ha ocurrido un error, intente nuevamente.");
          console.log(error);
          setLoading(false);
          window.grecaptcha.reset();
        });
      } else if(activeStep === 1) {
        setLoading(true);
        activateFreePass(personalData).then((response) => {
          setErrorMessage(response.data);
          console.log("!");
          setActiveStep(activeStep === steps.length - 1 ? 0 : activeStep + 1);
          setLoading(false);
        }).catch((error) => {
          setError(true);
          setErrorMessage(error.response?.data ?? "Ha ocurrido un error, intente nuevamente.");
          console.log(error);
          setLoading(false);
        });
      } else {
        activeStep === steps.length - 1 
          ? window.location.reload() 
          : setActiveStep(activeStep + 1)
      }
    }
  }

  return (
    <>
    <AlertMessage open={error} handleClose={handleCloseError} message={errorMessage} severity="error" />
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <Grid container className="StepperActivation">
      <Grid item sm={10} md={6} xl={4}>
        <Typography variant="h4" style={{margin: '1rem'}}>{texts.title}</Typography>
        <Typography variant="subtitle1" style={{margin: '1rem'}}>{texts.subtitle}</Typography>
        <Box sx={{ width: '100%' }}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <ColorlibStepLabel StepIconComponent={ColorlibStepIcon}>{label}</ColorlibStepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? 
            <StepBegin 
              number={number} 
              setNumber={setNumber} 
              verifier={verifier} 
              setVerifier={setVerifier} 
              terms={terms}
              setTerms={setTerms}
              error={errorsFirstStep}
            />
            : activeStep === 1 ? 
              <StepYourData 
                personalData={personalData} 
                setPersonalData={setPersonalData} 
                error={errorsSecondStep}
              />
              : 
                <StepConfirmation />
          }
        </Box>
        <Button variant="contained" color="secondary" style={{fontWeight: 'bold', margin: '1rem'}} onClick={nextStep}>{buttonLabels[activeStep]}</Button>
      </Grid>
    </Grid>
    </>
  );
}

export default StepperActivation;