import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Ocp-Apim-Subscription-Key'] = '77fe9b834093414b97c40d0d67feb975';

export async function verifyFreePass(number, verifier, tokenCaptcha) {
    return await axios.get('/Pase/VerificarPase', { params: { numeroPase: number, codigoPase: verifier, tokenCaptcha: tokenCaptcha } });
}

export async function activateFreePass(data) {
    return await axios.post('/Pase/ActivarPase', data);
}