import { Typography } from '@mui/material';
import React from 'react';
import './StepConfirmation.css';
import TextsJSON from '../../data/texts.json';

function StepConfirmation() {
  const isFiter = ['true', '1', 't'].includes(process.env.REACT_APP_IS_FITER.toLowerCase());
  const texts = isFiter ? TextsJSON.fiter : TextsJSON.megatlon;

  return (
    <div className="StepConfirmation">
      <Typography variant="body1" style={{whiteSpace: 'pre-line', margin: '1rem'}}>{texts.stepDescription[2]}</Typography>
    </div>
  );
}

export default StepConfirmation;
