import React from 'react';
import Grid from '@mui/material/Grid';
import logo from '../../assets/imgs/logo-133.png';
import logoFiter from '../../assets/imgs/logo-fiter-bottom.svg';
import { useTheme } from '@mui/material/styles';
import './Footer.css';
import TextsJSON from '../../data/texts.json';

function Footer() { 
  const theme = useTheme();
  const isFiter = ['true', '1', 't'].includes(process.env.REACT_APP_IS_FITER.toLowerCase());
  const texts = isFiter ? TextsJSON.fiter : TextsJSON.megatlon;

  return (
    <footer className="Footer" style={{ backgroundColor: theme.palette.footer.main }}>
      <Grid item xs={11} className='footerContainer'>
        {isFiter ? 
          <img src={logoFiter} width='80' height='30' alt='logo' /> 
          : <img src={logo} width='38' height='38' alt='logo' /> 
        }
        <span className='copyright' style={{ color: theme.palette.footer.text }}>{texts.footer + new Date().getFullYear()}</span>
      </Grid>
    </footer>
  );
}

export default Footer;
