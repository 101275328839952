import React from 'react';
import { Grid, TextField, MenuItem, Typography } from '@mui/material'; 
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './StepYourData.css';
import TextsJSON from '../../data/texts.json';

const documentTypes = [
  {
    value: 1,
    label: "DNI"
  },
  // {
  //   value: 2,
  //   label: "LC"
  // },
  // {
  //   value: 3,
  //   label: "CI"
  // },
  // {
  //   value: 5,
  //   label: "LE"
  // },
  {
    value: 6,
    label: "Pasaporte"
  }
];

const sex = [
  {
    value: 'M',
    label: "Masculino",
  },
  {
    value: 'F',
    label: "Femenino",
  },
  {
    value: 'O',
    label: "Otrx",
  }
];

// const maritalStatus = [
//   {
//     value: 1,
//     label: "Soltero"
//   },
//   {
//     value: 2,
//     label: "Casado"
//   },
//   {
//     value: 3,
//     label: "Divorciado"
//   },
//   {
//     value: 4,
//     label: "Viudo"
//   }
// ];

const TextMaskPhone = React.forwardRef(function TextMaskPhone(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value }})}
    />
  );
});

TextMaskPhone.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function StepYourData({personalData, setPersonalData, error}) {
  const isFiter = ['true', '1', 't'].includes(process.env.REACT_APP_IS_FITER.toLowerCase());
  const texts = isFiter ? TextsJSON.fiter : TextsJSON.megatlon;

  const handleChangePersonalData = (index, value) => {
    const regexOnlyNum = /^[0-9\b]+$/;
    const regexOnlyLetters = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g;

    if((index === "numeroDocumento" && personalData.tipoDocumento === 1) || index === "telefonoFijoCodigoArea"){
      if(value === "" || regexOnlyNum.test(value)) {
        setPersonalData({
          ...personalData,
          [index]: value
        });
      }
    } else if(index === "nombre" || index === "apellido") {
      if(value === "" || regexOnlyLetters.test(value)) {
        setPersonalData({
          ...personalData,
          [index]: value
        });
      }
    } else if (index === "tipoDocumento"){
      setPersonalData({
        ...personalData,
        numeroDocumento: '',
        [index]: value
      })
    }else {
      setPersonalData({
        ...personalData,
        [index]: value
      });
    }
  };
  return (
    <div className="StepYourData">
      <Typography variant="body1">{texts.stepDescription[1]}</Typography>
      <Grid container>
        <Grid item sm={12} md={6}  className="input">
          <TextField 
            id="outlined-basic" 
            className="textField" 
            error={!!error.tipoDocumento}
            helperText={error.tipoDocumento}
            label="Tipo de documento" 
            variant="outlined" 
            select
            value={personalData.tipoDocumento} 
            onChange={(e) => handleChangePersonalData("tipoDocumento", e.target.value)} 
          >
            {documentTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={12} md={6}  className="input">
          <TextField 
            id="outlined-basic" 
            className="textField" 
            error={!!error.numeroDocumento}
            helperText={error.numeroDocumento}
            disabled={!personalData.tipoDocumento}
            label="Número de documento" 
            variant="outlined" 
            value={personalData.numeroDocumento} 
            onChange={(e) => handleChangePersonalData("numeroDocumento", e.target.value)} 
          />
        </Grid>
        <Grid item sm={12} md={6}  className="input">
          <TextField 
            id="outlined-basic" 
            className="textField" 
            error={!!error.nombre}
            helperText={error.nombre}
            label="Nombre" 
            variant="outlined" 
            value={personalData.nombre} 
            onChange={(e) => handleChangePersonalData("nombre", e.target.value)} 
          />
        </Grid>
        <Grid item sm={12} md={6}  className="input">
          <TextField 
            id="outlined-basic" 
            className="textField" 
            error={!!error.apellido}
            helperText={error.apellido}
            label="Apellido" 
            variant="outlined" 
            value={personalData.apellido} 
            onChange={(e) => handleChangePersonalData("apellido", e.target.value)} 
          />
        </Grid>
        <Grid item sm={12} md={6}  className="input">
          <TextField 
            id="outlined-basic" 
            className="textField" 
            error={!!error.mail}
            helperText={error.mail}
            label="Email" 
            variant="outlined" 
            value={personalData.mail} 
            onChange={(e) => handleChangePersonalData("mail", e.target.value)} 
          />
        </Grid>
        <Grid item sm={12} md={6} className="input">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Fecha de nacimiento"
            className="textField" 
            variant="outlined"
            inputFormat="DD/MM/YYYY"
            maxDate={new Date()}
            value={personalData.fechaNacimiento}
            onChange={(e) => handleChangePersonalData("fechaNacimiento", e)}
            renderInput={(params) => 
              <TextField 
                {...params}
                error={!!error.fechaNacimiento}
                helperText={error.fechaNacimiento} 
              />
            }
          />
        </LocalizationProvider>
        </Grid>
        <Grid item sm={12} md={6}  className="input">
          <TextField 
            id="outlined-basic" 
            className="textField" 
            error={!!error.sexo}
            helperText={error.sexo}
            label="Sexo" 
            variant="outlined" 
            select
            value={personalData.sexo} 
            onChange={(e) => handleChangePersonalData("sexo", e.target.value)} 
          >
            {sex.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* <Grid item sm={12} md={6} className="input">
          <TextField 
            id="outlined-basic" 
            className="textField" 
            error={!!error.estadoCivil}
            helperText={error.estadoCivil}
            label="Estado civil" 
            variant="outlined" 
            select
            value={personalData.estadoCivil} 
            onChange={(e) => handleChangePersonalData("estadoCivil", e.target.value)} 
          >
            {maritalStatus.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid> */}
      </Grid>
      <Grid container>
        <Grid item sm={12} md={6} className="input">
          <Grid container>
            <Grid item md={12} style={{width: '100%'}}>
              <Typography variant="body1" style={{marginBottom: '1rem'}}>Teléfono</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic" 
                className="textField" 
                variant="outlined"
                error={!!error.telefonoCodigoArea}
                helperText={error.telefonoCodigoArea}
                value={personalData.telefonoCodigoArea}
                onChange={(e) => handleChangePersonalData("telefonoCodigoArea", e.target.value)}
                label="Cod."
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="formatted-text-mask-input"
                variant="outlined"
                className="textField" 
                error={!!error.telefonoNumero}
                helperText={error.telefonoNumero}
                value={personalData.telefonoNumero}
                onChange={(e) => handleChangePersonalData("telefonoNumero", e.target.value.replace(/-/g, ""))}
                name="telefonoNumero"
                label="Número"
                InputProps={{
                    inputComponent: TextMaskPhone
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default StepYourData;