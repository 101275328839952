import React from 'react';
import MegatlonLogo from '../../assets/imgs/logo.svg';
import FiterLogo from '../../assets/imgs/logo-fiter.svg';
import { useTheme } from '@mui/material/styles';
import './Header.css';

function Header(){
  const theme = useTheme();
  const isFiter = ['true', '1', 't'].includes(process.env.REACT_APP_IS_FITER.toLowerCase());
  return (
    <header className="Header"
      style={{
        backgroundColor: theme.palette.header.main, 
        borderBottom: `solid 1px ${theme.palette.text.primary}`
      }}>
      <div className="logo">
        <nav>
          <a href='/'>
            <img src={isFiter ? FiterLogo : MegatlonLogo} width="145" height="50" alt="logo Megatlon" />
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
