import React from 'react';
import { 
  TextField, 
  Checkbox, 
  FormGroup, 
  FormControl, 
  FormControlLabel, 
  FormHelperText, 
  Grid, 
  Typography
} from '@mui/material';
import './StepBegin.css';
import TextsJSON from '../../data/texts.json';
import ReCaptcha from '../ReCaptcha/ReCaptcha';

function StepBegin({number, setNumber, verifier, setVerifier, terms, setTerms, error}){
  const isFiter = ['true', '1', 't'].includes(process.env.REACT_APP_IS_FITER.toLowerCase());
  const texts = isFiter ? TextsJSON.fiter : TextsJSON.megatlon;

  const handleChangeNumber = (e) => {
    const regex = /^[0-9\b]+$/;
    if ((e.target.value === "" || regex.test(e.target.value)) && ((isFiter && e.target.value.length < 9) || e.target.value.length < 7)) {
      setNumber(e.target.value);
    }
  }  
  
  const handleChangeVerifier = (e) => {
    if (e.target.value.length < 3) {
      setVerifier(e.target.value);
    }
  } 

  const handleChangeTerms = (event) => {
    setTerms(event.target.checked);
  }

  return (
    <div className="StepBegin">
      <Typography variant="body1">{texts.stepDescription[0]}</Typography>
      <Grid container>
        <Grid item xs={8} style={{marginTop: '1rem'}}>
          <TextField 
            id="outlined-basic" 
            className="textField" 
            error={!!error.number}
            helperText={error.number}
            label="Número" 
            variant="outlined" 
            value={number} 
            onChange={handleChangeNumber} 
          />
        </Grid>
        <Grid item xs={8} style={{marginTop: '1rem'}}>
          <TextField 
            id="outlined-basic" 
            className="textField" 
            error={!!error.verifier}
            helperText={error.verifier}
            label="Letras" 
            variant="outlined" 
            value={verifier} 
            onChange={handleChangeVerifier} 
          />
        </Grid>
        <FormControl
          error={!!error.terms}
          component="fieldset"
          variant="standard"
        >
          <FormGroup>
            <FormControlLabel 
              control={
                <Checkbox 
                  checked={terms} 
                  onChange={handleChangeTerms} 
                />
              } 
              label="Acepto las condiciones de uso de Free Pass" 
            />
            <FormHelperText>{error.terms}</FormHelperText>
          </FormGroup>
        </FormControl>
        <Grid container direction='column'>
          <ReCaptcha />
          {!!error.captcha ? 
            <Typography variant='caption' color='error.main'>{error.captcha}</Typography> : <></>
          }
        </Grid>
      </Grid>
    </div>
  );
} 

export default StepBegin;
