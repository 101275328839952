import React, { useEffect } from 'react';

function ReCaptcha() {
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
      
    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js";
        document.body.appendChild(script)
    }, [])

    return(
        <div
            className="g-recaptcha"
            data-sitekey={reCaptchaKey}
        ></div>
    );
}

export default ReCaptcha